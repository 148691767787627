import useCarousel from 'hooks/useCarousel'
import cx from 'classnames'
import TextButton from 'components/TextButton'
import ChevronButton from 'components/ChevronButton'
import Image from 'components/Image'
import { screens } from 'lib/constants'
import useMedia from 'use-media'
import * as analytics from 'lib/analytics'

export default function ImageCarousel({ module, settings }) {
  const isScreenMedium = useMedia({ minWidth: screens.m })

  const { headline, link, items } = module

  const { ref, prevProps, nextProps } = useCarousel({
    containScroll: 'trimSnaps',
  })

  return (
    <div
      className={cx('l:flex items-center', {
        'mt-50 l:mt-100': settings.hasMarginTop,
        'mb-50 l:mb-100': settings.hasMarginBottom,
      })}
    >
      <div className="px-15 s:px-25 m:px-20 l:px-60 xl:px-80 mb-20 flex-shrink-0">
        <div className="max-w-330">
          {headline ? (
            <h2 className="text-28 l:text-32 font-medium leading-130">{headline}</h2>
          ) : null}
          {link ? (
            <TextButton
              link={link}
              chevron={true}
              size={isScreenMedium ? 'large' : 'compact'}
              onClick={() => analytics.trackButtonClick(link.title)}
            >
              {link.title}
            </TextButton>
          ) : null}
        </div>
      </div>
      <div className="relative w-full pl-16 l:px-62 overflow-hidden">
        <div className="overflow-hidden" ref={ref}>
          <div className="flex">
            {items?.length
              ? items.map((item) => {
                  return (
                    <div
                      key={item._key}
                      className="relative px-6 l:px-8 m:min-w-25p"
                    >
                      <div className="overflow-hidden w-160 m:w-full rounded">
                        <Image
                          image={item.image}
                          width={300}
                          height={300}
                          sizes={`50vw, (min-width: ${screens.s}) 33vw, (min-width: ${screens.l}) 20vw`}
                          alt='img'
                        />
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        </div>

        <div className="block l:hidden h-full w-24 s:w-40 l:w-60 xl:w-80 absolute top-0 right-0 z-1 bg-gradient-to-r from-transparent-white to-white transition pointer-events-none" />
        <div className="hidden l:block absolute z-1 left-0 xs:left-5 s:left-10 top-1/2 transform -translate-y-1/2">
          <ChevronButton className='border-none bg-transparent' direction="left" label="Previous slide" {...prevProps} chevronIconClassName='w-15'/>
        </div>
        <div className="hidden l:block absolute z-1 right-0 xs:right-5 s:right-10 top-1/2 transform -translate-y-1/2">
          <ChevronButton className='border-none bg-transparent' direction="right" label="Next slide" {...nextProps} chevronIconClassName='w-15'/>
        </div>
      </div>
    </div>
  )
}
